import Swiper from 'swiper/bundle';

export const swiper = new Swiper('.swiper-home', {
  // Optional parameters
  slidesPerView: 1,
  draggable: true,
  loop: false,
  grabCursor: true,

  // If we need pagination
  pagination: {
    el: '.swiper-home-pagination',
    type: 'bullets',
    clickable: true,
  },
});

export const swiperReviewThumb = new Swiper('.swiper-review-thumb', {
  spaceBetween: 3,
  slidesPerView: 5,
  watchSlidesProgress: true,
  centerInsufficientSlides: true,
  centeredSlides: true,
  loop: true,
});

export const swiperReview = new Swiper('.swiper-review', {
  loop: true,
  navigation: {
    nextEl: '.reviews-slider__btn-nav-next',
    prevEl: '.reviews-slider__btn-nav-prev',
  },
  thumbs: {
    swiper: swiperReviewThumb,
  },
});
